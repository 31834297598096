import React, { useCallback, useEffect, useState } from "react";
import Dropdown from "components/dropdown";
import { FaRegUserCircle } from "react-icons/fa";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { predefinedPaths } from "utils/consts";
import HeaderCrums from "./breadcrumbs";
import api from "api";
import MyPopupAccountComponent from "components/popups/account.jsx";
// import api from "api";
// import navbarimage from "assets/img/layout/Navbar.png";
// import { BsArrowBarUp } from "react-icons/bs";
// import { FiSearch } from "react-icons/fi";
// import { RiMoonFill, RiSunFill } from "react-icons/ri";
// import {
//   IoMdNotificationsOutline,
//   IoMdInformationCircleOutline,
// } from "react-icons/io";
// import avatar from "assets/img/avatars/avatar4.jpg";

const Navbar = (props) => {
  const { onOpenSidenav, routes } = props;
  // const [open, setOpen] = React.useState(true);
  

  // const [darkmode, setDarkmode] = React.useState(false);
  const navigate = useNavigate();
  const handleLogout = useCallback((e) => {
    console.log(e);
    api.logout(api.endpoint_admin_signout);
    navigate("/auth");
  });

  const user = api.getCurrentUser() || [];
  const username = user.me ? user.me.name : "";

  const [popupVisible, setPopupVisible] = useState(false);
  const togglePopup = () => {
    setPopupVisible(true);
  }

  console.log("popupVisible",popupVisible);


  return (
    <>
      {popupVisible?<MyPopupAccountComponent container=".app_main" popupVisible={popupVisible} onHide={()=>{setPopupVisible(false)}} />:<></>}
      
      <nav className="sticky top-1 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>

        {/* breakcrumbs */}
        <div className="ml-[6px]">
          <HeaderCrums />
          {/* <div className="h-6 w-[224px] pt-1">
          <span
            className="text-sm font-normal text-navy-700 hover:none dark:text-white dark:hover:text-white"
            href=" "
          >
            Home
          </span>
          <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
            {" "}
            /{" "}
          </span>

          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p> */}
        </div>

        <div className="relative mt-[3px] flex h-[61px] flex-grow items-center justify-around gap-2 rounded-full px-2 py-2 md:flex-grow-0 md:gap-1 xl:gap-2">
          {/* Profile & Dropdown */}
          <Dropdown
            button={
              <FaRegUserCircle className="h-8 w-8 cursor-pointer  text-gray-800 dark:text-white" />
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
            children={
              <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="p-4">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold text-navy-700 dark:text-white ">
                      👋 Hello, [{username || "user"}]
                    </p>{" "}
                  </div>
                </div>
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="flex flex-col p-4">
                  <span
                    className="link text-sm text-gray-800 dark:text-white hover:dark:text-white"
                    onClick={togglePopup}
                  >
                    My Account
                  </span>
                  <button
                    // href="/signout"
                    onClick={handleLogout}
                    className="mt-3 text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in"
                  >
                    Log Out
                  </button>
                </div>
              </div>
            }
          />
        </div>

      </nav>
    </>
  );
};

export default Navbar;
