
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "views/auth/SignIn";
// import routes from "routes.js";
// import { predefinedPaths } from "utils/consts";

export default function Auth() {
  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     return (
  //       <Route path={`/${prop.path}`} element={prop.component} key={key} /> //this is for auth
  //     );
  //   });
  // };
  document.documentElement.dir = "ltr";
  return (
<>
    <header class="gsma-header gradient" role="banner">
		<div class="inner-header">
			
			<section class="logo">
				<a class="header_nav" href="https://www.gsma.com/">
					<img src="https://www.gsma.com/wp-content/themes/theme_gsma_2023/images_2023/GSMA-Logo-White-RGB.png" alt="GSMA logo" />
				</a>
			</section>
			<nav class="main-nav" role="navigation">
				
				
			</nav>
				
		</div>
	</header>

    <main className={`mx-auto min-h-screen max-h-screen`}>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <section className=" min-h-screen flex items-center justify-center">
          <Routes>
            <Route path="/*" element={<SignIn />} />
          </Routes>
        </section>

      </div>
    </main>
    </>
  );
}
