import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import api from "api";
import * as utils from "utils/utils";
import ComponentRegistry from "components/ComponentRegistry";
import MyMessagePopupComponent from "components/popup_message";
// import MyMessagePopupComponent from "components/popup_message";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();

  const [open, setOpen] = React.useState(true);
  const [popupVisible, setPopupVisible] = React.useState(true);

  const [routes, setRoutes] = React.useState([])
  const fetchRoutes = () => {
    const routes_endpoint = window._env_.REACT_APP_API_URL_ROUTES; // `admin/gui/routes`
    api.get(routes_endpoint, null, (response) => {
      console.log("fetchRoutes", response);
      const routes = response.data;
      setRoutes(routes);
      api.setItem("routes", routes);
    });
  }
  // get menu
  const [menuItems, setMenuItems] = React.useState([])
  const [consentItems, setConsentItems] = React.useState();
  
  const [defaultMenuItem, setDefaultMenuItem] = React.useState(null);
  
  const fetchMenuItems = () => {
    const menu_endpoint = window._env_.REACT_APP_API_URL_MENU //`admin/gui/menu`
    api.get(menu_endpoint, null, (response) => {
      const menuItems = response.data;
      // menuItems.sort((a, b) => (a.display_order < b.display_order) ? -1 : 1);
      menuItems.sort(utils.sort_by(('display_order', false, parseInt)));
      
      setMenuItems(menuItems);
      menuItems.map((item) => {
        if (item.is_default == 1) {
          setDefaultMenuItem(item);
          return;
        }
      });
  
    });
  };

  const fetchConsents = () => {
    const menu_endpoint = '/me/consents';
    api.get(menu_endpoint, {is_accepted:0}, (response) => {
      const items = response.data;
      items.sort((a, b) => (a.id > b.id) ? -1 : 1);
      setConsentItems(items);
      return;
    });
  };

  React.useEffect(() => {
    fetchRoutes();
  }, []);

  React.useEffect(() => {
    fetchMenuItems();
  }, []);

  React.useEffect(() => {
    fetchConsents();
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  // let's check active route and then set the page title
  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf("/" + routes[i].path) !== -1
      ) {
        // setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((item) => {
      const prop = item;
      console.log("getRoutes", prop.path, prop.options, prop.elem);
      if (prop.path !== "") {
        // const url = utils.ProcessPath(prop.path);
        const routeKey = prop.id;//+"_"+this.state.selectedIndex;
        return (
          <Route path={`/${prop.path}`} element={<ComponentRegistry tag={prop.elem} item={prop} />} key={routeKey} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";


  return (
    <div className="flex h-full w-full">
      <MyMessagePopupComponent items={consentItems} container=".app_main" />
      <Sidebar menuItems={menuItems} open={open} onClose={() => setOpen(false)} />

      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900 min-h-screen">
      
        {/* Main Content */}
        <main className={`mx-[2px] h-full flex-none transition-all md:pr-1 xl:ml-[260px]`}        >
        
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              secondary={getActiveNavbar(menuItems)}
              routes={getRoutes(routes)}
              {...rest}
            />

            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-1 md:pr-2">
              <Routes>
                {getRoutes(routes)}
                {defaultMenuItem ? <Route
                  path="/"
                  element={<Navigate to={defaultMenuItem.path} replace />}
                /> : <></>}
              </Routes>
            </div>

            <div className="p-3">
              <Footer />
            </div>

          </div>
          
        </main>
        
      </div>
    </div>
  );
}
